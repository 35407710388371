<template>

 <router-view></router-view>
  <notifications class="mt"  />
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>
<style scoped>
.mt{
  margin-top: 15px;
  margin-right: 15px;
}
</style>
