// import { authHeader } from './auth-header';
import moment from "moment";
import phpUnserialize from "phpunserialize";
import axios from 'axios';
import store from "@/state/store";
import { notify } from "@kyvg/vue3-notification";
export const customFunction = {
    DateThaiYear,
    getPermissionUser,
    issetVar,
    getUrlIndexAllLists,
    getUserData,
    _notify,

};
function _notify(type='success', msg=''){
    notify({
        // title: "แจ้งเตือน",
        text: msg,
        type: type,
    });
}
function DateThaiYear() {
    const year = parseInt(moment().format('YYYY')) + 543;
    const result = year.toString()
    return result.substring(2);
}

function getPermissionUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    // var phpUnserialize = require('phpunserialize');
    const res = phpUnserialize(user.data);
    return res;
}

function issetVar(val) {
    if (typeof val == 'undefined') {
        return false;
    } else {
        return true
    }
}

function getUrlIndexAllLists(type) {
    let resUrl = '';
    if (process.env.NODE_ENV == 'development') {
        resUrl = 'https://cmm-building.com/api/test';
    } else {
        // resUrl = 'https://www.chiangmai-building.com/member2/empList';
        resUrl = 'https://cmm-building.com/api/test';
    }
    if(type == 'api'){
        resUrl = 'https://cmm-building.com/api/test';
    }else{
        resUrl = '';
    }

    // resUrl = '';
    return resUrl;
}

async function getUserData() {

    let res = false;
    const username_login = localStorage.getItem('username_login');

    if((username_login) != null)
    {
        _notify('warning','loading');
        res = true;
        const form = new FormData();
        form.append('localUsername', username_login);
        const result = await axios.post('https://www.chiangmai-building.com/auth/login_api2', form, {
            headers: {
                'content-type': 'text/json'
            }
        });
        if (result.data.status == 'success') {
            _notify('success','load userdata success');
              localStorage.setItem('user', JSON.stringify(result.data.data));
              store.commit('authfack/loginSuccess', result.data.data);
            res = true;
        } else {
            res = false;
        }
    }
    return res;
}

// function getLocalStorage(){
//     let users = JSON.parse(localStorage.getItem('users'))
// }

// function login(email, password) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify({ email, password })
//     };
//
//     return fetch(`/users/authenticate`, requestOptions)
//         .then(handleResponse)
//         .then(user => {
//             // login successful if there's a jwt token in the response
//             if (user.token) {
//                 // store user details and jwt token in local storage to keep user logged in between page refreshes
//                 localStorage.setItem('user', JSON.stringify(user));
//             }
//             return user;
//         });
// }
