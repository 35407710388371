import {userService} from '../../helpers/authservice/user.service';
import router from '../../router/index'

const user = JSON.parse(localStorage.getItem('user'));
export const state = user
    ? {
        status: {loggeduser: true}, user, cmm_obj_main: {}, cmm_obj: {},
        err: {}
    }
    : {
        status: {},
        user: null,
        cmm_obj_main: {},
        cmm_obj: {},
        err: {},
    };

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({dispatch, commit}, {email, password}) {
        commit('loginRequest', {email});

        userService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    router.push('/');
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('notification/error', error, {root: true});
                }
            );
    },
    // Logout the user
    logout({commit}) {
        userService.logout();
        commit('logout');
    },
    // register the user
    registeruser({dispatch, commit}, user) {
        commit('registerRequest', user);
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', {root: true});
                    router.push('/login');
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, {root: true});
                }
            );
    },
    // Logout the user
    cmm({commit}, data) {
        // userService.logout();

        commit('cmm', data);
    },
    delete_cmm({commit}) {
        // userService.logout();
        commit('delete_cmm');
    },

};

export const mutations = {
    loginRequest(state, user) {
        state.status = {loggingIn: true};
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = {loggeduser: true};
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = {registering: true};
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
    cmm(state, data) {
        state.cmm_obj_main = data;
        state.cmm_obj = data;
    },
    delete_cmm(state) {
        // let obj = JSON.stringify(state.cmm_obj_main);
        // let new_obj = JSON.parse(obj);
        state.cmm_obj = [];
        // try {
        //     if (new_obj.length > 0) {
        //         state.cmm_obj = new_obj.slice(index);
        //     }
        // } catch (e) {
        //     state.err = e;
        //     // console.log(e)
        // }

    }
};

export const getters = {
    // Whether the user is currently logged in.
    cmm(state) {
        return state.cmm_obj
    }
}
